import React from "react"
import { graphql } from "gatsby"
import UseCaseHeader from "../components/new/useCaseHeader/useCaseHeader"
import UseCaseSection from "../components/new/useCaseSection/useCaseSection"
import Container from "../components/new/container/container"
import Layout from "../components/new/layout/layout"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MDXProvider } from "@mdx-js/react"
import HubspotContactFormPartner from "../components/hubspotContactFormPartner"
import Head from "../components/head"
import Paragraph from "../components/new/paragraph/paragraph"
import SubHeader from "../components/new/subHeader/subHeader"

export const query = graphql`
    query($slug: String!) {
        contentfulSeller(slug: {eq: $slug}) {
            slug
            title
		    logo
		    website
        }
    }
`

const Seller = (props) => {

   return (
            <Layout navbar="transparent">
                <Head title={''} description={''}/>
                <UseCaseHeader title={''} subtitle={''} image={''}/>
                {/* <Container style='desktop-only'>
                        {props.data.contentfulUseCase.sections.map((node) => {
                            return(
                                <UseCaseSection title={node.title} description={node.description.description} image={node.image} order={node.order}/>
                            )
                        })}
            </Container>
                <Container style='mobile-only'>
                        {props.data.contentfulUseCase.sections.map((node) => {
                            return(
                                <UseCaseSection title={node.title} description={node.description} image={node.image} order='1'/>
                            )
                        })}
            </Container>
            <div id='form' style={{backgroundImage:`url(${props.data.contentfulUseCase.imageHeader})`, backgroundSize: 'cover', padding: '3rem 0'}}>
                <Container>
                    <div className="row mt-5 align-items-start">
                        <div className="desktop-only col-md-6">
                            <SubHeader title={''} style='light'/>
                            <Paragraph text={''} style='light w-75'/>
                            <img src={''} className="w-75 mt-5"/>
                        </div>
                        <div className="col-12 mobile-only" style={{margin: '0 auto', textAlign: 'center'}}>
                            <SubHeader title={''} style='light'/>
                            <Paragraph text={''} style='light'/>
                            <img src={''} className="w-100 mt-5"/>
                        </div>
                        <div className="col-12 col-md-6 bg-lol2 pt-4">
                            <p className="text-white pl-4" style={{fontWeight:'600', fontSize: '16px'}}>Complete the form and we'll send you a calendar link</p>
                            <HubspotContactFormPartner id={''} />
                        </div>
                    </div>
                </Container>
            </div> */}
            </Layout> 
    )
}


export default Seller
